import React from "react"
import logo from "../assets/venn-logo-4.svg"
import { FaBars } from "react-icons/fa"
import PageLinks from "../constants/links"
import { Link } from "gatsby"
const Navbar = ({toggleSidebar}) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/"><img src={logo} alt="logo" /></Link>
          
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaBars></FaBars>
          </button>
        </div>
        <PageLinks styleClass="nav-links"></PageLinks>
      </div>
    </nav>
  )
}
export default Navbar
